.memory-grid-container {
    background-color: #d12f31;
    padding-top: 20px;
    margin-bottom: 20px;
    height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
}

.memory-grid {
    background-color: #d12f31;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 170px 170px 170px;
    grid-gap: 50px;
    padding: 20px 40px;
    height: calc(100vh - 40px);
    width: initial
}

.memory-grid-card-container{
    width: 100%;
    height: 100%;
    position: relative;
}

.memory-grid-card {
    width: 100%;
    height: 100%;
    background-color: #000;
    object-fit: contain;
    border-radius: 12px;
    /* box-shadow: 8px 8px 16px #111; */
    cursor: pointer;
}


.memory-grid-card-container::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: 10px;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s;
}

.memory-grid-card-container::after {
    background-color: rgb(167, 19, 95);
    animation: casa 1s linear;
}

@keyframes casa {
  0%{
    opacity: 1;
    transform: scaleX(1) scaleY(1);
  }
  100% {
    opacity: 0;
    transform: scaleX(1.4) scaleY(1.6);
  }
}

.memory-grid-card:active {
    background-color: #010;
    transform: translateY(0.1em);
    box-shadow: 6px 6px 12px #111;
}

.meter {
    box-sizing: content-box;
    height: 20px; 
    position: relative;
    background: rgb(20, 20, 20);
    padding: 10px;
    box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
    width: 80%;
    margin: 0 auto;
}

.meter > span {
    display: block;
    height: 100%;
    box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
        inset 0 -2px 6px rgba(0, 0, 0, 0.4);
    position: relative;
    overflow: hidden;
    background-image: linear-gradient(rgb(224, 36, 134), rgb(189, 21, 107));
}

.counter-text{
    margin: 0 auto;
    padding-bottom: 80px;
    font-size: 60px;
    font-weight: 800;
}

.boom {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
}