.button {
    cursor: pointer;
    background-color: rgb(227, 0, 82);
    /* box-shadow: 8px 8px 16px #111; */
}

.button:active {
    transform: translateX(4px) translateY(4px);
    background-color: rgb(208, 48, 111);
    /* box-shadow: 6px 6px 12px #111; */
}

.button.beat {
  animation: beat 1s linear infinite;
}

@keyframes beat {
  0%,
  100% {
    transform: scale(1)
  }
  50% {
    transform: scale(0.9)
  }
}