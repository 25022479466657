.input.beat:not(:checked) {
  animation: beat 1s linear infinite;
}

@keyframes beat {
0%,
100% {
  transform: scale(1)
}
50% {
  transform: scale(0.95)
}
}