.face-tracker-video {
  filter: url(/src/assets/prey/filter.svg#thermal);
}

.face-tracker-canvas {
  position: absolute;
}

.laser {
  position: absolute;
}
